import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from "@mui/icons-material/Info";
import {
	Alert,
	Button,
	Chip,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Snackbar,
	TextField,
	Typography,
	styled,
} from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import DataContext from "../api/context";
import { calculateBasicEndDay, validateCoupon } from "../api/menu";
import {
	payOrder,
	payOrderAccount,
	saveOnlineOrder,
	saveOrder,
} from "../api/order";
import { getPaymentConfig } from "../api/payment";
import { calculateDaysIncludingEnds } from "../api/resources";
import { createSubscription, pendingSubscription } from "../api/subscription";
import LocationSearchInput from "./LocationSearchInput";
import ServiceFeesDialog from "./ServiceFeesDialog";

// Define a styled container to ensure responsive layout
const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	maxWidth: "800px",
	margin: "auto",
	padding: theme.spacing(2),
	boxSizing: "border-box",
	[theme.breakpoints.down("sm")]: {
		padding: theme.spacing(1),
	},
}));

const OrderSchema = Yup.object().shape({
	menuOpt: Yup.object().shape({}).required("Required"),
	rrOpt: Yup.object().shape({}).required("Required"),
	selPlan: Yup.object().shape({}).required("Required"),
	prodType: Yup.object().shape({}).required("Required"),
	request: Yup.string(),
	price: Yup.string(),
	subscriptionPrice: Yup.string(),
	billingType: Yup.string().required("Required"),
	sDate: Yup.date().required("Required"),
	eDate: Yup.date().required("Required"),
	confNum: Yup.string().required("Required"),
	serviceOpt: Yup.string().required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
	coupon: Yup.string(),
	paymentMode: Yup.string().required("Required"),
	savings: Yup.object().shape({}),
	renewalDate: Yup.date(),
});

const OrderForm = ({
	_id,
	SelID,
	tname,
	vegPrice,
	nvegPrice,
	products,
	serviceTypes,
	interacEmail,
	notifyEmail,
	delArea,
	isVerified,
	delAreaNote,
	paymentModesEnabled,
	serviceFees = "0",
	plansConfig,
	postalConfig,
	taxConfig,
	coupons,
	defaultExtras,
	cutOffTime,
	refundPolicyUrl,
}) => {
	const { user, setOpen, setLoading } = useContext(DataContext);
	const [sellerAccount, setSellerAccount] = useState();
	const [coord, setCoord] = useState();
	const [plans, setPlans] = useState([]);
	const [menuOptions, setMenuOptions] = useState([]);
	const [rrOptions, setRrOptions] = useState([]);

	const [extras, setExtras] = useState({});
	const [extrasOptions, setExtrasOptions] = useState([]);
	const [paymentOptions, setPaymentOptions] = useState([]);
	const [paymentMethodChoose, setPaymentMethodChoose] = useState("");
	const [defaultPayment, setDefaultPayment] = useState("");
	const [snack, setSnack] = useState(false);
	const [openSFDialog, setOpenSFDialog] = useState(false);
	const [couponVal, setCouponVal] = useState("");
	const [selDiscount, setSelDiscount] = useState();
	const [initial, setInitial] = useState({
		prodType: "",
		menuOpt: "",
		rrOpt: "",
		selPlan: "",
		request: "",
		days: 0,
		rechargeDays: 0,
		deliveryDays: [1, 2, 3, 4, 5],
		postalCode: "",
		sDate: "",
		confNum: "N/A",
		serviceOpt: "Delivery",
		email: "",
		coupon: "",
		paymentMode: "",
		billingType: "",
	});

	const handleKeyPress = (event) => {
		setCouponVal("");
		const regex = /[^a-zA-Z0-9]/;
		if (regex.test(event.key)) {
			event.preventDefault();
		}
	};

	const loadPayAccount = async () => {
		setSellerAccount(await payOrderAccount(SelID));
	};

	const reInitialize = (initial, add) => {
		setInitial(initial);
	};

	function getNextValidDate(validWeekdays, cutoffTime) {
		// Start with today
		let nextDate = moment();

		// Check if today's date is valid and if the current time is before the cutoff time
		if (validWeekdays.includes(nextDate.day())) {
			const currentTime = moment().format("HH:mm:ss");
			if (
				moment(currentTime, "HH:mm:ss").isBefore(moment(cutoffTime, "HH:mm:ss"))
			) {
				return nextDate.format("YYYY-MM-DD");
			}
		}

		// Loop until a valid weekday is found
		do {
			nextDate.add(1, "days");
		} while (!validWeekdays.includes(nextDate.day()));

		return nextDate.format("YYYY-MM-DD");
	}

	const getExtrasConfig = async () => {
		let payMode;
		if (paymentModesEnabled.includes("card")) {
			payMode = "card";
			setDefaultPayment("card");
		}
		setInitial({ ...initial, ...defaultExtras, ...{ paymentMode: payMode } });
	};

	function getTimestampAndIsToday(inputDate) {
		var now = moment();
		var inputMoment = moment(inputDate, "YYYY-MM-DD");
		var isToday = now.format("YYYY-MM-DD") === inputMoment.format("YYYY-MM-DD");

		if (isToday) {
			// If it's today, return the current timestamp and that it's today
			return {
				initiated: Math.floor(Date.now() / 1000), // Current timestamp in seconds
				isSameDay: isToday,
			};
		} else {
			// If it's not today, return the timestamp for 5 AM of the given date and that it's not today
			return {
				initiated: Math.floor(
					inputMoment.startOf("day").add(5, "hours").valueOf() / 1000
				),
				isSameDay: isToday,
			};
		}
	}

	const getTotal = (values, type) => {
		let deliveryCharges = 0;
		deliveryCharges =
			plansConfig?.[values?.selPlan?.planName]?.deliveryCharges ?? 0;
		if (
			postalConfig?.[values?.postalCode]?.deliveryFees?.[
				values?.selPlan?.planName
			]
		) {
			deliveryCharges =
				postalConfig?.[values?.postalCode]?.deliveryFees?.[
					values?.selPlan?.planName
				];
		}
		let extrastotal = 0;
		extrasOptions?.map((option) => {
			extrastotal +=
				parseFloat(values[`x${option}`] ? values[`x${option}`] : 0) *
				parseFloat(extras[option]);
		});
		let total =
			extrastotal +
			(values?.subscriptionPrice &&
			(values?.billingType === "subscription" ||
				values?.billingType === undefined)
				? parseFloat(values?.subscriptionPrice)
				: parseFloat(values?.price)) +
			(values?.serviceOpt === "Delivery" ? parseFloat(deliveryCharges) : 0);
		if (values?.paymentMode === "card") {
			total = total + (total * parseFloat(serviceFees)) / 100;
		}
		if (type === "cost") {
			return !isNaN(total) ? total.toFixed(2) : (0).toFixed(2);
		}
		let totalBeforeCoupon = total;
		if (couponVal === "valid") {
			let selCoupon = coupons.find((c) => c.name === values.coupon);

			total = total - (parseFloat(selCoupon?.percent_off) * total) / 100;
		}
		if (type === "validatedCoupon") {
			return coupons.find((c) => c.name === values.coupon);
		}
		if (type === "discount") {
			if (couponVal === "valid") {
				let selCoupon = coupons.find((c) => c.name === values.coupon);

				return (
					<span>
						<span style={{ marginRight: 20 }}>
							{selCoupon.percent_off}% off{"    "}
						</span>
						<span style={{ fontWeight: "bolder" }}>
							C$
							{parseFloat(
								(parseFloat(selCoupon?.percent_off) * totalBeforeCoupon) / 100
							).toFixed(2)}
						</span>
					</span>
				);
			} else {
				return;
			}
		}

		if (type === "delFees") {
			return !isNaN(deliveryCharges) ? deliveryCharges : (0).toFixed(2);
		}
		if (type === "tax") {
			return !isNaN(total)
				? (total * ((taxConfig?.show ? taxConfig?.taxRate : 0) / 100)).toFixed(
						2
				  )
				: (0).toFixed(2);
		}
		if (type === "totalWithoutCoupon") {
			return !isNaN(totalBeforeCoupon)
				? (
						totalBeforeCoupon +
						totalBeforeCoupon *
							((taxConfig?.show ? taxConfig?.taxRate : 0) / 100)
				  ).toFixed(2)
				: (0).toFixed(2);
		}
		return !isNaN(total)
			? (
					total +
					total * ((taxConfig?.show ? taxConfig?.taxRate : 0) / 100)
			  ).toFixed(2)
			: (0).toFixed(2);
	};

	const getPaymentOptions = async () => {
		const { data } = await getPaymentConfig(paymentModesEnabled);
		setPaymentOptions(data);
	};
	const truncateText = (text, maxLength) => {
		if (text.length > maxLength) {
			return text.substring(0, maxLength) + "...";
		}
		return text;
	};
	const maxLength = 50;
	useEffect(() => {
		loadPayAccount();
		getExtrasConfig();
		getPaymentOptions();
	}, []);

	const formik = useFormik({
		initialValues: initial,
		enableReinitialize: true,
		validationSchema: OrderSchema,
		onSubmit: async (values) => {
			if (
				values.serviceOpt === "Pickup" ||
				(address && address === selectedAdd)
			) {
				if (user) {
					setLoading(true);
					if (values.serviceOpt !== "Pickup") {
						values.address = address;
						values.coord = coord;
						values.deliveryCharges = getTotal(values, "delFees");
					}
					values.cost = getTotal(values, "cost");
					values.tax = getTotal(values, "tax");
					values.totalPrice = getTotal(values, "");
					values.notifyEmail = notifyEmail;
					values.SelID = SelID;
					values.tname = tname;
					values.trans_Config = getTimestampAndIsToday(values?.sDate);
					let temp = Object.assign({}, values, user);
					let interval_days = calculateDaysIncludingEnds(
						values.sDate,
						values.eDate
					);

					let anchor_date = Math.floor(
						moment().add(1, "days").startOf("day").add(5, "hours").valueOf() /
							1000
					);
					if (values.paymentMode === "card") {
						if (couponVal === "valid") {
							temp.coupon = getTotal(values, "validatedCoupon");
							temp.stripeCouponId = getTotal(
								values,
								"validatedCoupon"
							).stripeCouponId;
						}
						if (values.billingType === "once") {
							const result = await payOrder({
								tname: tname,
								price: values.totalPrice * 100,
								sellerAccount: sellerAccount,
								menuID: _id,
							});

							temp.checkoutSession = result;
							temp.isOnlineOrder = true;
							temp.payout = values.totalPrice;
							const { data: stripeData } = temp.checkoutSession;
							temp.ch_id = stripeData.id;
							const res = await saveOnlineOrder(temp);
							temp.cusOrderId = res.insertedId;
							const data = await saveOrder(temp);
							window.location.href = stripeData.url;
						} else if (values.billingType === "subscription") {
							let result;
							let subscriptionObject = {
								stripeCouponId: temp.stripeCouponId,
								amount: getTotal(values, "totalWithoutCoupon") * 100,
								sellerAccount: sellerAccount,
								menuID: _id,
								recurring: {
									interval: "day",
									interval_count: values?.selPlan?.rechargeDays,
								},
								product_data: {
									name: tname + " " + values?.prodType?.prodName,
									active: true,
									statement_descriptor: "DT Meals",
									unit_label: 1,
								},
							};
							if (!values?.trans_Config?.isSameDay) {
								subscriptionObject = {
									...subscriptionObject,
									...{ anchor_date: values?.trans_Config?.initiated },
								};
							}
							result = await createSubscription(subscriptionObject);
							temp.subscriptionSession = result;
							temp.isOnlineOrder = true;
							temp.payout = values.totalPrice;
							const { data: stripeData } = temp.subscriptionSession;
							temp.ch_id = stripeData.id;
							const data = await pendingSubscription(temp);
							window.location.href = stripeData.url;
						}

						setLoading(false);
					} else {
						temp.isOnlineOrder = true;
						const data = await saveOrder(temp);
						window.location.href = "/orders";
						setLoading(false);
					}
				} else {
					setOpen(true);
				}
			} else {
				setAddErr(true);
			}
		},
	});

	const [address, setAddress] = useState("");
	const [selectedAdd, setSelectedAdd] = useState();
	const [addErr, setAddErr] = useState(false);

	return (
		<>
			<ServiceFeesDialog
				openSFDialog={openSFDialog}
				setOpenSFDialog={setOpenSFDialog}
				serviceFees={serviceFees}
			/>
			<form enableReinitialize={true} onSubmit={formik.handleSubmit}>
				<Grid item container direction="row" style={{ padding: "8px" }}>
					<Snackbar
						open={snack}
						autoHideDuration={2000}
						onClose={() => setSnack(false)}
						message="Copied to clipboard"
					/>
					<FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
						<InputLabel id={"prodType"}>Select Type</InputLabel>
						<Select
							id="prodType"
							name="prodType"
							style={{
								color: "#9F6BA0",
								borderColor: "#9F6BA0",
								borderRadius: 40,
							}}
							values={formik.values.prodType}
							label="Select Type"
							onChange={(e) => {
								formik.setFieldValue("prodType", e.target.value);
								formik.setFieldValue(
									"deliveryDays",
									e.target.value?.deliveryDays
								);
								setPlans(e.target.value?.plans);
								setExtras([]);
								setExtrasOptions([]);
								setRrOptions([]);
								setMenuOptions([]);
								formik.setFieldValue("days", 0);
								formik.setFieldValue("rechargeDays", 0);
								formik.setFieldValue("selPlan", "");
								formik.setFieldValue("price", "");
								formik.setFieldValue("subscriptionPrice", "");
								formik.setFieldValue("billingType", "");
								formik.setFieldValue("menuOpt", "");
								setCouponVal("");
								formik.setFieldValue("price", "");
							}}
							error={formik.touched.prodType && Boolean(formik.errors.prodType)}
						>
							{products.map((option, index) => (
								<MenuItem key={index} value={option}>
									{truncateText(`${option.prodName} `, maxLength)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{products?.map((product, key) => (
						<Grid item xs={12} style={{ padding: 3 }}>
							<Typography
								style={{
									fontFamily: "Poppins, sans-serif",
									fontWeight: "bold",
								}}
							>
								•
								<span
									style={{
										color: "#9F6BA0",
										marginLeft: 5,
									}}
								>
									{product.prodName}
								</span>{" "}
								- <span style={{ color: "gray" }}>{product.description}</span>
							</Typography>
						</Grid>
					))}
					{plans?.length > 0 && (
						<FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
							<InputLabel id={"selPlan"}>Select Plan</InputLabel>
							<Select
								labelId={"selPlan"}
								id={"selPlan"}
								name={"selPlan"}
								style={{
									color: "#9F6BA0",
									borderColor: "#9F6BA0",
									borderRadius: 40,
								}}
								value={formik.values.selPlan}
								label={"Select Plan"}
								onChange={async (e) => {
									setExtrasOptions([]);
									formik.setFieldValue("rrOpt", "");
									formik.setFieldValue("menuOpt", "");
									formik.setFieldValue("price", "");
									formik.setFieldValue("subscriptionPrice", "");
									formik.setFieldValue("billingType", "");
									setCouponVal("");
									const starDate = getNextValidDate(
										formik.values?.deliveryDays,
										cutOffTime
									);
									formik.setFieldValue("sDate", starDate);
									const { data } = await calculateBasicEndDay({
										startDate: starDate,
										deliveryDays: formik.values?.deliveryDays,
										days: e.target.value?.days,
									});
									formik.setFieldValue("eDate", data);
									formik.setFieldValue("selPlan", e.target.value);
									formik.setFieldValue(
										"renewalDate",
										moment(starDate, "YYYY-MM-DD")
											.add(parseInt(e.target.value?.rechargeDays), "days")
											.format("YYYY-MM-DD")
									);
									setExtras(e.target.value?.extrasPrice);
									formik.setFieldValue("days", e.target.value?.days);
									formik.setFieldValue(
										"rechargeDays",
										e.target.value?.rechargeDays
									);
									formik.setFieldValue("savings", "");
									setMenuOptions(e.target.value?.menuOpts);
								}}
								error={formik.touched.selPlan && Boolean(formik.errors.selPlan)}
							>
								{plans.map((option, index) => (
									<MenuItem key={index} value={option}>
										{truncateText(`${option?.description}`, maxLength)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{menuOptions?.length > 0 && (
						<FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
							<InputLabel id={"menuOpt"}>Select Menu</InputLabel>
							<Select
								labelId={"menuOpt"}
								id={"menuOpt"}
								name={"menuOpt"}
								value={formik.values.menuOpt}
								style={{
									color: "#9F6BA0",
									borderColor: "#9F6BA0",
									borderRadius: 40,
								}}
								label={"Select Menu"}
								onChange={(e) => {
									formik.setFieldValue("rrOpt", "");
									formik.setFieldValue("billingType", "");
									setCouponVal("");
									formik.setFieldValue("menuOpt", e.target.value);
									formik.setFieldValue("price", e.target.value?.price);
									formik.setFieldValue(
										"subscriptionPrice",
										e.target.value?.subscriptionPrice
									);
									if (
										parseFloat(e.target.value?.subscriptionPrice) !==
										parseFloat(e.target.value?.price)
									) {
										formik.setFieldValue(
											"savings",
											e.target.value?.subscriptionPrice < e.target.value?.price
												? {
														type: "subscription",
														amount:
															e.target.value?.price -
															e.target.value?.subscriptionPrice,
												  }
												: {
														type: "once",
														amount:
															e.target.value?.subscriptionPrice -
															e.target.value?.price,
												  }
										);
									}
									setRrOptions(e.target.value?.rrOpt);
									setExtrasOptions(e.target.value?.extrasOpt);
									reInitialize(initial, extrasOptions);
								}}
								error={formik.touched.menuOpt && Boolean(formik.errors.menuOpt)}
							>
								{menuOptions.map((option, index) => (
									<MenuItem key={index} value={option}>
										{truncateText(`${option?.menuType}`, maxLength)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{rrOptions?.length > 0 && (
						<FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
							<InputLabel id={"rrOpt"}>Select roti/rice option</InputLabel>
							<Select
								labelId={"rrOpt"}
								id={"rrOpt"}
								name={"rrOpt"}
								style={{
									color: "#9F6BA0",
									borderColor: "#9F6BA0",
									borderRadius: 40,
								}}
								value={formik.values.rrOpt}
								label={"Select default option"}
								onChange={(e) => {
									formik.setFieldValue("rrOpt", e.target.value);
								}}
								error={formik.touched.rrOpt && Boolean(formik.errors.rrOpt)}
							>
								{rrOptions.map((option, index) => (
									<MenuItem key={index} value={option}>
										{Object.keys(option).map((key) => {
											return (
												(option[key] > 0
													? option[key] === 1
														? ""
														: option[key]
													: " no") +
												" " +
												_.capitalize(key) +
												" |"
											);
										})}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<Grid item xs={12} container spacing={1}>
						{extrasOptions?.length > 0 &&
							extrasOptions.map((option, index) => (
								<Grid item key={index}>
									<TextField
										style={{
											width: 115,
											color: "#9F6BA0",
											borderColor: "#9F6BA0",
										}}
										InputProps={{
											style: {
												borderRadius: "40px",
											},
										}}
										id={`x${option}`}
										name={`x${option}`}
										label={`Extra ${_.capitalize(option)}`}
										type="number"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										inputProps={{ min: 0 }}
										value={
											formik.values[`x${option}`] <= 0
												? ""
												: formik.values[`x${option}`]
										}
										onChange={(e) => {
											if (e.target.value >= 0 && e.target.value <= 10) {
												formik.setFieldValue(
													`x${option}`,
													Math.ceil(e.target.value)
												);
											}
										}}
										error={
											formik.touched[`x${option}`] &&
											Boolean(formik.errors[`x${option}`])
										}
									/>
								</Grid>
							))}
					</Grid>
					{formik.values.selPlan?.days !== undefined && (
						<Grid item xs={12} container alignItems="center">
							<TextField
								style={{
									marginBlock: 10,
									color: "#9F6BA0",
									borderColor: "#9F6BA0",
								}}
								id="sDate"
								fullWidth
								name="sDate"
								type="date"
								label="Start Date"
								variant="outlined"
								placeholder="YYYY-MM-DD"
								InputProps={{
									style: {
										borderRadius: "40px",
									},
								}}
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									min: getNextValidDate(
										formik.values?.deliveryDays,
										cutOffTime
									),
								}}
								value={formik.values?.sDate}
								onChange={async (e) => {
									const inputDate = e.target.value;
									if (
										!moment(inputDate, "YYYY-MM-DD").isValid() ||
										!moment(inputDate).isSameOrAfter(
											getNextValidDate(formik.values?.deliveryDays, cutOffTime)
										)
									) {
										window.alert("Please enter the correct date");
										// formik.setFieldValue("sDate", "");
										// formik.setFieldValue("eDate", "");
										// formik.setFieldValue("renewalDate", "");
									} else {
										formik.setFieldValue("sDate", inputDate);
										const { data } = await calculateBasicEndDay({
											startDate: moment(inputDate, "YYYY-MM-DD").format(
												"YYYY-MM-DD"
											),
											deliveryDays: formik.values?.deliveryDays,
											days: formik.values.selPlan?.days,
										});
										formik.setFieldValue("eDate", data);
										formik.setFieldValue(
											"renewalDate",
											moment(inputDate, "YYYY-MM-DD")
												.add(formik.values?.rechargeDays, "days")
												.format("YYYY-MM-DD")
										);
									}
								}}
								error={formik.touched.sDate && Boolean(formik.errors.sDate)}
								helperText={`Our cut off time is ${cutOffTime}, orders after ${cutOffTime} would start from next day`}
							/>
						</Grid>
					)}
					<TextField
						fullWidth
						id="request"
						name="request"
						style={{
							marginBottom: 10,
							marginTop: 10,
							color: "#9F6BA0",
							borderColor: "#9F6BA0",
							borderRadius: 20,
						}}
						InputProps={{
							style: {
								borderRadius: "20px",
							},
						}}
						label="Additional Instructions"
						onChange={formik.handleChange}
						error={formik.touched.request && Boolean(formik.errors.request)}
						multiline
						rows={3}
					/>
					{serviceTypes?.length > 0 && (
						<FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
							<InputLabel id={"serviceOpt"}>Select Service</InputLabel>
							<Select
								labelId={"serviceOpt"}
								id={"serviceOpt"}
								name={"serviceOpt"}
								style={{
									color: "#9F6BA0",
									borderColor: "#9F6BA0",
									borderRadius: 40,
								}}
								value={formik.values.serviceOpt}
								label={"Service Type"}
								onChange={(e) => {
									formik.setFieldValue("serviceOpt", e.target.value);
								}}
								error={
									formik.touched.serviceOpt && Boolean(formik.errors.serviceOpt)
								}
							>
								{serviceTypes.map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<input type="hidden" id="addr" />
					{formik.values.serviceOpt !== "Pickup" && (
						<LocationSearchInput
							delArea={delArea}
							setAddress={setAddress}
							address={address}
							selectedAdd={selectedAdd}
							setSelectedAdd={setSelectedAdd}
							error={addErr}
							delAreaNote={delAreaNote}
							setCoord={setCoord}
							tname={tname}
							setFieldValue={formik.setFieldValue}
						/>
					)}
					{paymentOptions.length > 0 && (
						<FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
							<InputLabel id={"paymentMode"}>Pay using</InputLabel>
							<Select
								id="paymentMode"
								name="paymentMode"
								style={{
									color: "#9F6BA0",
									borderColor: "#9F6BA0",
									borderRadius: 40,
								}}
								values={formik.values.paymentMode}
								defaultValue={defaultPayment}
								label="Pay using"
								onChange={(e) => {
									if (e.target.value === "interac") {
										formik.setFieldValue("coupon", "");
										setCouponVal("");
										formik.setFieldValue("billingType", "once");
										formik.setFieldValue("confNum", "");
									} else if (e.target.value === "card") {
										formik.setFieldValue("coupon", "");
										setCouponVal("");
										formik.setFieldValue("confNum", "N/A");
									} else {
										formik.setFieldValue("coupon", "");
										setCouponVal("");
										formik.setFieldValue("billingType", "once");
										formik.setFieldValue("confNum", "N/A");
									}
									formik.setFieldValue("paymentMode", e.target.value);
								}}
								error={
									formik.touched.paymentMode &&
									Boolean(formik.errors.paymentMode)
								}
							>
								{paymentOptions.map((option, index) => (
									<MenuItem key={index} value={option.value}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{formik.values?.menuOpt && formik.values.paymentMode === "card" && (
						<FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
							<InputLabel id={"billingType"}>Billing Type</InputLabel>
							<Select
								id="billingType"
								name="billingType"
								style={{
									borderRadius: 40,
									color: "#9F6BA0",
									borderColor: "#9F6BA0",
								}}
								values={formik.values.billingType}
								label="Billing Type"
								onChange={(e) => {
									setCouponVal("");
									formik.setFieldValue("billingType", e.target.value);
								}}
								error={
									formik.touched.billingType &&
									Boolean(formik.errors.billingType)
								}
								sx={{
									textAlign: "center",
									"& .MuiSelect-select": {
										textAlign: "center",
										paddingRight: "24px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										height: "100%",
									},
								}}
							>
								{formik.values?.price && (
									<MenuItem
										key="once"
										value="once"
										style={{
											alignContent: "center",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										One Time{" "}
										{formik.values?.savings?.type === "once" && (
											<Chip
												style={{
													backgroundColor: "#9f6ba0",
													color: "white",
													marginLeft: 20,
												}}
												label={`Saving C$${formik.values?.savings?.amount}`}
											/>
										)}
									</MenuItem>
								)}
								{formik.values?.subscriptionPrice && (
									<MenuItem
										key="subscription"
										value="subscription"
										style={{
											alignContent: "center",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										Subscribe and Save{" "}
										{formik.values?.savings?.type === "subscription" && (
											<Chip
												style={{
													backgroundColor: "#9f6ba0",
													color: "white",
													marginLeft: 20,
												}}
												label={`Saving C$${formik.values?.savings?.amount}`}
											/>
										)}
									</MenuItem>
								)}
							</Select>
						</FormControl>
					)}
					<TextField
						fullWidth
						id="email"
						name="email"
						label="Email"
						type="email"
						value={formik.values.email}
						values
						style={{
							color: "#9F6BA0",
							borderColor: "#9F6BA0",
						}}
						InputProps={{
							style: {
								borderRadius: "40px",
							},
						}}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
					/>
					{formik.values.paymentMode === "card" && (
						<FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
							<InputLabel htmlFor="coupon">Coupon</InputLabel>
							<OutlinedInput
								fullWidth
								id="coupon"
								name="coupon"
								label="Coupon"
								type="coupon"
								value={formik.values.coupon}
								onKeyDownCapture={handleKeyPress}
								style={{
									borderRadius: 40,
									color: "#9F6BA0",
									borderColor: "#9F6BA0",
								}}
								InputProps={{
									style: {
										borderRadius: "40px",
									},
								}}
								endAdornment={
									<InputAdornment position="end">
										<Button
											aria-label="toggle password visibility"
											onClick={async () => {
												if (!!!user) {
													setOpen(true);
												} else {
													if (
														coupons.find(
															(c) =>
																c.name === formik.values.coupon.toUpperCase()
														)
													) {
														const couponVal = await validateCoupon({
															SelID: SelID,
															CusID: user.CusID,
															billingType: formik.values.billingType,
															name: formik.values.coupon.toUpperCase(),
															price: getTotal(formik.values, "cost"),
														});
														if (couponVal.data) {
															formik.setFieldValue(
																"coupon",
																formik.values.coupon.toUpperCase()
															);
															setCouponVal("valid");
														} else {
															setCouponVal("invalid");
														}
													} else {
														setCouponVal("invalid");
													}
												}
											}}
											edge="end"
										>
											APPLY
										</Button>
									</InputAdornment>
								}
								onChange={formik.handleChange}
								error={formik.touched.coupon && Boolean(formik.errors.coupon)}
							/>
						</FormControl>
					)}

					{couponVal === "valid" && formik.values.paymentMode === "card" && (
						<Grid item xs={12} container style={{ padding: 10 }}>
							<Alert
								style={{ width: "100%" }}
								icon={<CheckIcon fontSize="inherit" />}
								severity="success"
							>
								Valid coupon code
							</Alert>
						</Grid>
					)}
					{couponVal === "invalid" && formik.values.paymentMode === "card" && (
						<Grid item xs={12} container style={{ padding: 10 }}>
							<Alert
								style={{ width: "100%" }}
								icon={<CloseIcon fontSize="inherit" />}
								severity="error"
							>
								Invalid coupon code
							</Alert>
						</Grid>
					)}

					<Grid item xs={12}>
						<Typography
							variant={"h6"}
							style={{ marginTop: 20, fontWeight: "bold", fontSize: 25 }}
						>
							Summary
						</Typography>
					</Grid>
					<Grid item xs={12} container>
						<Grid
							item
							xs={6}
							container
							direction="column"
							style={{ marginTop: 40 }}
						>
							{formik.values.selPlan?.planName && (
								<Typography>{formik.values.prodType?.prodName}</Typography>
							)}
							{formik.values.selPlan?.planName && (
								<Typography>{formik.values.selPlan?.planName}</Typography>
							)}
							{formik.values?.menuOpt && (
								<Typography>{formik.values.menuOpt?.menuType}</Typography>
							)}
							{formik.values.rrOpt && (
								<Typography>
									{formik.values.rrOpt?.roti > 0
										? formik.values.rrOpt?.roti
										: "no"}{" "}
									roti &{" "}
									{formik.values.rrOpt?.rice > 0
										? formik.values.rrOpt?.rice
										: "no"}{" "}
									rice
								</Typography>
							)}
							{extrasOptions?.map((option) => {
								return (
									formik.values[`x${option}`] > 0 && (
										<Typography key={option}>
											{formik.values[`x${option}`]}
											{" Extra " + _.capitalize(option)}
										</Typography>
									)
								);
							})}
							{formik.values?.coupon &&
								couponVal === "valid" &&
								formik.values.paymentMode === "card" && (
									<Typography style={{ color: "#45a149" }}>
										{formik.values?.coupon}
									</Typography>
								)}
							{formik.values?.eDate && (
								<Typography>
									{formik.values?.billingType === "subscription" ? (
										<Typography>
											Renews on - <strong>{formik.values?.renewalDate}</strong>
										</Typography>
									) : (
										<Typography>
											Ends on - <strong>{formik.values?.eDate}</strong>
										</Typography>
									)}{" "}
								</Typography>
							)}
						</Grid>
						<Grid item xs={6}>
							<Grid
								item
								xs={12}
								container
								justifyContent="right"
								alignItems="center"
								style={{ marginTop: 40 }}
							>
								<Typography>
									Cost{" "}
									{serviceFees && formik.values.paymentMode === "card" && (
										<InfoIcon
											onClick={() => setOpenSFDialog(true)}
											fontSize="small"
											style={{
												padding: 0,
												margin: 0,
												paddingBottom: 3,
												cursor: "pointer",
											}}
										/>
									)}
									<span
										style={{
											fontWeight: "bolder",
											marginLeft: 20,
											fontSize: 17,
										}}
									>
										<span style={{ marginRight: 2 }}>C$</span>
										{getTotal(formik.values, "cost")}
									</span>
								</Typography>
							</Grid>
							{getTotal(formik.values, "discount") &&
								formik.values.paymentMode === "card" && (
									<Grid
										item
										xs={12}
										container
										justifyContent="right"
										style={{ marginTop: 10 }}
									>
										<Typography>
											{getTotal(formik.values, "discount")}
										</Typography>
									</Grid>
								)}
							{taxConfig?.show && (
								<Grid
									item
									xs={12}
									container
									justifyContent="right"
									style={{ marginTop: 10 }}
								>
									<Typography>
										Tax{" "}
										<span
											style={{
												fontWeight: "bolder",
												marginLeft: 20,
												fontSize: 17,
											}}
										>
											<span style={{ marginRight: 2 }}>C$</span>
											{getTotal(formik.values, "tax")}
										</span>
									</Typography>
								</Grid>
							)}
							<Grid item xs={12} container justifyContent="right">
								<div
									style={{
										width: "112px",
										height: "7px",
										position: "relative",
										borderBottom: "1px solid #000000",
									}}
								></div>
							</Grid>
							<Grid
								item
								xs={12}
								container
								justifyContent="right"
								style={{ marginTop: 10 }}
							>
								<Typography>
									Total{" "}
									<span
										style={{
											fontWeight: "bolder",
											marginLeft: 20,
											fontSize: 25,
										}}
									>
										<span style={{ marginRight: 2 }}>C$</span>
										{getTotal(formik.values, "")}
									</span>
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					{formik.values.paymentMode === "interac" && (
						<>
							<Grid
								item
								xs={12}
								container
								alignItems="center"
								style={{ marginBottom: 20, marginTop: 10 }}
							>
								<Typography
									variant="body1"
									style={{
										marginLeft: 10,
										alignItems: "center",
									}}
								>
									<InfoIcon color="warning" style={{ marginRight: 10 }} />
									Please interac the amount to{" "}
									<span style={{ fontWeight: "bolder" }}>
										{interacEmail}
									</span>{" "}
									<span
										style={{ cursor: "pointer" }}
										onClick={() => {
											navigator.clipboard.writeText(interacEmail);
											setSnack(true);
										}}
									>
										<ContentCopyIcon />
									</span>{" "}
									and enter the confirmation number below
								</Typography>
							</Grid>
							<TextField
								fullWidth
								style={{
									marginBottom: 20,
									color: "#9F6BA0",
									borderColor: "#9F6BA0",
								}}
								InputProps={{
									style: {
										borderRadius: "40px",
									},
								}}
								id="confNum"
								name="confNum"
								label="Confirmation number"
								onChange={formik.handleChange}
								error={formik.touched.confNum && Boolean(formik.errors.confNum)}
							/>
						</>
					)}
					<Typography style={{ fontWeight: "bolder", marginTop: 10 }}>
						By proceeding with this purchase, you acknowledge and accept the
						seller's{" "}
						<span
							style={{ color: "#9F6BA0", textDecoration: "underline" }}
							onClick={() => window.open(refundPolicyUrl, "_blank")}
						>
							refund policy
						</span>
						.
					</Typography>
					{!isVerified ? (
						<Typography color="secondary">
							Seller currently doesn't accept any orders
						</Typography>
					) : (
						<Button
							variant="contained"
							type="submit"
							disabled={sellerAccount === undefined}
							fullWidth
							onClick={() => {
								if (formik.values.prodType === "") {
									document.getElementById("prodType").scrollIntoView({
										behavior: "smooth",
										block: "center",
										inline: "nearest",
									});
								}
								if (formik.values.selPlan === "") {
									document.getElementById("selPlan")?.scrollIntoView({
										behavior: "smooth",
										block: "center",
										inline: "nearest",
									});
								}
								if (formik.values.menuOpt === "") {
									document.getElementById("menuOpt")?.scrollIntoView({
										behavior: "smooth",
										block: "center",
										inline: "nearest",
									});
								}
								if (formik.values.rrOpt === "") {
									document.getElementById("rrOpt")?.scrollIntoView({
										behavior: "smooth",
										block: "center",
										inline: "nearest",
									});
								}
								if (formik.values.email === "") {
									document.getElementById("email")?.scrollIntoView({
										behavior: "smooth",
										block: "center",
										inline: "nearest",
									});
								}
								if (formik.values.sDate === "") {
									document.getElementById("sDate")?.scrollIntoView({
										behavior: "smooth",
										block: "center",
										inline: "nearest",
									});
								}
								if (
									formik.values.serviceOpt === "Delivery" &&
									(!address || address !== selectedAdd)
								) {
									document.getElementById("serviceOpt")?.scrollIntoView({
										behavior: "smooth",
										block: "center",
										inline: "nearest",
									});
								}
							}}
							style={{
								marginTop: 20,
								backgroundColor: "black",
								fontWeight: "bold",
								textTransform: "capitalize",
							}}
						>
							Pay
						</Button>
					)}
				</Grid>
			</form>
		</>
	);
};

export default OrderForm;
